import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/firestore';

const config = {
  apiKey: "AIzaSyA1R5sKjg0KmjsocvGJNWa6DIkU-MNv06g",
  authDomain: "player99-781f8.firebaseapp.com",
  databaseURL: "https://player99-781f8.firebaseio.com",
  projectId: "player99-781f8",
  storageBucket: "player99-781f8.appspot.com",
  messagingSenderId: "1089474526703",
  appId: "1:1089474526703:web:43112701b27693d9b80a1a",
  measurementId: "G-P35M0TH962"
};

firebase.initializeApp(config);
firebase.analytics()

export default firebase;
