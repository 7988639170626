import React, { Component } from 'react'
import LoginView from '../Login/LoginView.js'
var muted;

class ChatView extends Component {
  constructor(props) {
    super(props)
  }

  render() {

    return (
      <div className="Login">
        <LoginView />
        <h3>test</h3>
      </div>
    )
  }
}

export default ChatView
