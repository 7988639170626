import React, { Component } from 'react'
var muted;

class LoginView extends Component {
  constructor(props) {
    super(props)
  }

  render() {

    return (
      <form>
          <input type="text" placeholder="username" />
          <input type="password" placeholder="password" />
      </form>
    )
  }
}

export default LoginView
