import React, { Component } from 'react'
import firebase from '../Firebase.js'
import Moment from 'react-moment';

class Schedule extends Component {
  constructor(props) {
    super(props)
    this.state = {
      schedule: []
    }
  }

  getSchedule() {
    const db = firebase.firestore();
    const scheduleRef = db.collection("public").doc("schedule").collection("shows");

    scheduleRef.orderBy("time").get().then(snapshot => {
      this.setState({ schedule: []})
      snapshot.forEach(doc => {
        this.setState((prevState) => ({
          schedule: [...prevState.schedule, {
            id: doc.id,
            title: doc.data().title,
            time: doc.data().time
          }]
        }))
      })
    });
  }

  componentDidMount() {
    this.getSchedule();
  }

  render() {
    let scheduleList = this.state.schedule.map((d) => (
            <div className="Schedule-row" key={d.id}>
                <h3 className="Schedule-title">{d.title}</h3>

                <div className="Schedule-row-stack">
                  <p>
                    <Moment format="MM/DD">{d.time.toDate()}</Moment>
                  </p>

                  <p>
                    <Moment format="h:mmA">{d.time.toDate()}</Moment>
                  </p>
              </div>
            </div>
        )
      )

    let emptyList = <p>there are no upcoming shows</p>

    return (
      <div className="Schedule" id="schedule">
          <h2>schedule</h2>
          { this.state.schedule.length > 0 ? scheduleList : emptyList }
          <p style={{fontSize:8}}>View more at ETP.XYZ</p>
          <p style={{fontSize:8}}>*eastern standard time</p>

      </div>
    )
  }
}

export default Schedule
