import React, { Component } from 'react'

class StartButton extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isMuted: true
    }
  }

  render() {
    let button;
    if (this.state.isMuted) {
      button = <h3 onClick={()=>{
        this.setState({ isMuted: false})
        let audio = document.getElementById("AudioStream")
        audio.muted = false
        if (audio.paused) {
          audio.play()
        }
      }}>start</h3>
    } else {
      button = <h3 onClick={()=> {
        this.setState({ isMuted: true})
        let audio = document.getElementById("AudioStream")
        audio.muted = true
      }}>stop</h3>
    }

    return (
      <div className="Start">
        {button}
      </div>
    )
  }
}

export default StartButton
