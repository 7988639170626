import React, { Component } from 'react'
import Clock from 'react-live-clock';
import TrackInfo from './Components/TrackInfo.js'
import ChatView from './Components/Chat/ChatView.js'
import Schedule from './Components/Schedule.js'
import {
  isBrowser,
  isMobile
} from 'react-device-detect';
import dieterRamsDesktop from './P99 Radio Desktop.png';
import dieterRamsMobile from './P99 Radio Mobile.png';
import p99logo from './P99_RADIO.png';
import iklogo from './IK BUBBLE.png';
import iflogo from './friends.png';
import './App.css';
var muted;

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      schedule: []
    }
  }

  render() {
    let stereo = isMobile ? dieterRamsMobile : dieterRamsDesktop
    let clock = <Clock format={'hh:mm:ss'} ticking={true} />
    let shopUrl = "https://www.shop-player-99.com"
    let tweetUrl = "https://twitter.com/intent/tweet?text=Now%20listening%20to%20%23p99radio%20radio.player-99.com"

    return (
      <div className="App">
        <header className="App-header" id="">
        <div className="MenuBar">
          <a className="Home-Button" href="https://player-99.com/" style={{textDecoration: "none"}}>home</a>
          <a className="Main-Button"
          href="#"
          rel="noopener noreferrer"
          style={{textDecoration: "none"}}>
                radio
          </a>
          <a className="Schedule-Button"
          href="#schedule"
          rel="noopener noreferrer"
          style={{textDecoration: "none"}}>
                schedule
          </a>

          <a className="Shop-Button" href={shopUrl} target="_blank" rel="noopener noreferrer" style={{textDecoration: "none"}}>shop</a>

          <a className="Twitter-Button" href={tweetUrl} target="_blank" rel="noopener noreferrer" style={{textDecoration: "none"}}>tweet</a>
        </div>
        <div className="Spacer">
        </div>
        <img src={stereo} className="Stereo" alt="stereo" />

          <TrackInfo />
          <h3 className="Time">{clock}</h3>

        </header>

        <body className="App-body">
          <Schedule />
        </body>


        <footer>
        <div className="Logo-row">
          <a href="https://www.instagram.com/player99intl/" target="_blank" rel="noopener noreferrer">
            <img src={p99logo} className="NinetyNine" alt="play" />
          </a>
        </div>
        <audio muted={muted} id="AudioStream">
          <source src="https://audio.p99.media/listen/radio/radio.mp3" type="audio/mpeg"/>
          Your browser does not support this audio stream.
        </audio>
        </footer>
        </div>
    );
  }
}

export default App;
